import BackgroundVideo from '../../components/BackgroundVideo/BackgroundVideo';
import { useSelector } from 'react-redux';
import { RootState } from '../../Store';
import Music from '../Music/Music';
import { PageKey } from '../../features/Global/Global.slice';
import Layout2 from './Layout2';
import Videos from '../Videos/Videos';
import { musicData2 } from '../Music/MusicData';
import AUDIO from '../../assets/videos/bg.desktop.mp4';
import BackgroundMusic from '../../components/BackgroundMusic/BackgroundMusic';

const Home2 = () => {
  const { playing, key, fallbackRes } = useSelector((state: RootState) => state.Global);

  return (
    <>
      {!fallbackRes ? null : <BackgroundMusic src={AUDIO} />}
      <Layout2 show={key === PageKey.HOME} />
      <Music show={key === PageKey.MUSIC} data={musicData2} />
      <Videos show={key === PageKey.VIDEOS} />
    </>
  );
};

export default Home2;
