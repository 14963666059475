import { MotionProps, Variants } from 'framer-motion';
import { TransitionPowerThree, TransitionPowerTwo } from './Transitions';

export const FadeInThenUp: MotionProps = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
  transition: { ...TransitionPowerThree, duration: 0.8 },
};

export const FadeIn: MotionProps = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 1,
  },
  transition: { ...TransitionPowerThree, duration: 0.8 },
};

export const fadeInFadeOut: MotionProps = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
  transition: {
    delay: 0.4,
    duration: 0.8,
    ...TransitionPowerThree,
  },
};

export const FadeVariant: Variants = {
  hidden: {
    opacity: 0,
    transition: TransitionPowerTwo,
  },
  visible: {
    opacity: 100,
    transition: TransitionPowerTwo,
  },
};
