import { YoutubeItemTypes } from '../../components/YoutubeVideoSlider/types';

export const VideoData: YoutubeItemTypes[] = [
  {
    videoId: 'gXA5kja2Jh0',
    title: 'NGeeYL - Wrong Move (feat. Trippie Redd) [Official Video]',
  },
  {
    videoId: 'PknGmKMWDGY',
    title: 'NGeeYL - Red Light / Escalade [Official Video]',
  },
];
