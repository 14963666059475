import { PageKey, setFallbackRes, setKey, setPlaying } from '../../features/Global/Global.slice';
import Store from '../../Store';

export default class GlobalService {
  setKey(payload: PageKey) {
    Store.dispatch(setKey(payload));
  }
  setPlaying(payload: boolean) {
    Store.dispatch(setPlaying(payload));
  }
  setFallbackRes(payload: boolean) {
    Store.dispatch(setFallbackRes(payload));
  }
}
