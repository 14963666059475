import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import { Arrow } from './styled.components';
import { YoutubeItemTypes } from './types';
import VideoItem from './VideoItem';

const Container = styled.div`
  @media (min-width: 768px) {
  }
`;

interface VideoSliderProps {
  playlistId?: string;
  data: YoutubeItemTypes[];
}

export default function VideoSlider({ playlistId, data }: VideoSliderProps) {
  const [youtubeItems, setYoutubeItems] = useState<YoutubeItemTypes[]>(data);

  useEffect(() => {
    //   get the youtube playlist
    if (playlistId) {
      handleRequest();
    }
  }, [playlistId, data]);

  async function handleRequest() {
    const REACT_APP_YOUTUBE_API = 'AIzaSyCapJ5cgPo02rxlhEeHSEJwgV-nQRKnucA';
    const youtube_api = REACT_APP_YOUTUBE_API;
    const video_list = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=${playlistId}&fields=items(snippet(resourceId%2FvideoId%2Cthumbnails%2Fmedium%2Furl%2Ctitle))&key=${youtube_api}`;

    const { data } = await axios.get(video_list);

    const filteredData: YoutubeItemTypes[] = [];

    data.items.map((item: any) => {
      filteredData.push({
        videoId: item.snippet.resourceId.videoId,
        title: item.snippet.title,
      });
    });

    setYoutubeItems([...youtubeItems, ...filteredData]);
  }

  const renderArrowPrev = (onClickHandler: any, hasPrev: boolean, label: string) => (
    <Arrow type="button" onClick={onClickHandler} left="38%" title={label}>
      <FontAwesomeIcon icon={faAngleLeft as any} />
    </Arrow>
  );

  const renderArrowNext = (onClickHandler: any, hasNext: boolean, label: string) => (
    <Arrow type="button" onClick={onClickHandler} right="38%" title={label}>
      <FontAwesomeIcon icon={faAngleRight as any} />
    </Arrow>
  );

  return (
    <Container className="w-full flex justify-center items-center relative">
      <Carousel
        infiniteLoop={false}
        swipeable
        emulateTouch
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        showIndicators={false}
        renderArrowNext={renderArrowNext}
        renderArrowPrev={renderArrowPrev}
      >
        {youtubeItems &&
          youtubeItems.map((item, idx) => (
            <VideoItem key={idx} idx={idx} title={item.title} videoId={item.videoId} />
          ))}
      </Carousel>
    </Container>
  );
}
