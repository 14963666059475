import {
  faApple,
  faFacebook,
  faInstagram,
  faSpotify,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

// temporary types (need to be debugged)
export interface SocialDataTypes {
  name: string;
  icon: any;
  to: string;
}

export const data: SocialDataTypes[] = [
  {
    name: 'instagram',
    icon: faInstagram,
    to: 'https://www.instagram.com/ngeeyl/',
  },
  {
    name: 'twitter',
    icon: faTwitter,
    to: 'https://twitter.com/bigbaglegend?lang=en',
  },
  {
    name: 'facebook',
    icon: faFacebook,
    to: 'https://www.facebook.com/Bigbaglegend',
  },
  {
    name: 'youtube',
    icon: faYoutube,
    to: 'https://www.youtube.com/channel/UCVJFvXzR-J9rORkJu2W-nwg',
  },
  {
    name: 'spotify',
    icon: faSpotify,
    to: 'https://open.spotify.com/artist/5Z9mvkWNEpiKVQ36xVkMfe?si=qI1vyZBWREW3EyxaemQNkw',
  },
  {
    name: 'apple-music',
    icon: faApple,
    to: 'https://music.apple.com/ph/artist/ngeeyl/1287648993',
  },
];
