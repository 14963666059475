import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import { fadeInFadeOut } from '../FramerMotion/Fade';
import { SlideLeftSlideRight, SlideRightSlideLeft } from '../FramerMotion/Slide';
import { Arrow, SlideItem } from './styled.components';
import { MusicDetail, MusicImage } from './styled.components';
import { MusicSliderDataTypes } from './types';

const Container = styled(motion.div)``;

interface MerchSliderProps {
  data: MusicSliderDataTypes[];
}

export default function MusicSlider({ data }: MerchSliderProps) {
  const renderArrowPrev = (onClickHandler: any, hasPrev: boolean, label: string) => (
    <Arrow type="button" onClick={onClickHandler} left="43.85%" title={label}>
      <FontAwesomeIcon icon={faAngleLeft as any} />
    </Arrow>
  );

  const renderArrowNext = (onClickHandler: any, hasNext: boolean, label: string) => (
    <Arrow type="button" onClick={onClickHandler} right="43.85%" title={label}>
      <FontAwesomeIcon icon={faAngleRight as any} />
    </Arrow>
  );

  const items = data.map((item, idx) => {
    return (
      <SlideItem
        key={idx}
        className="text-white text-center overflow-visible flex flex-col lg:flex-row justify-center items-center w-3/4 mx-auto"
      >
        <MusicImage {...SlideRightSlideLeft} href={item.link}>
          <img src={item.img} alt="ffm image" className="transition-all w-full" />
        </MusicImage>
        <MusicDetail {...SlideLeftSlideRight} className="px-10 mx-auto mt-5 md:mt-0">
          <h2 className="font-bold italic text-xl md:text-2xl">{item.title}</h2>
          {/* <a href={item.link} className="uppercase text-md md:text-xl">
            stream / download now
          </a> */}
        </MusicDetail>
      </SlideItem>
    );
  });

  return (
    <Container {...fadeInFadeOut} className="overflow-visible overscroll-none relative">
      <Carousel
        infiniteLoop
        swipeable
        emulateTouch
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        showArrows={false}
        renderArrowNext={renderArrowNext}
        renderArrowPrev={renderArrowPrev}
      >
        {items}
      </Carousel>
    </Container>
  );
}
