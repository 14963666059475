import { Variants } from 'framer-motion';

const primaryColor = '#000000';

export const hover: Variants = {
  hidden: {
    opacity: 0,
    width: 0,
  },
  visible: {
    width: '75%',
    opacity: 1,
  },
};

export const colorHover: Variants = {
  hidden: {
    color: 'white',
  },
  visible: {
    color: primaryColor,
  },
};

export const slideDownVariant: Variants = {
  hidden: {
    height: '0%',
  },
  visible: {
    height: 'auto',
  },
};

export const widthVariant: Variants = {
  hidden: {
    width: '0%',
    transition: {
      delay: 0,
    },
  },
  visible: {
    width: '100%',
    transition: {
      delay: 0,
    },
  },
};

export const displayVariant: Variants = {
  hidden: {
    display: 'none',
  },
  visible: {
    display: 'block',
  },
};
