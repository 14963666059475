import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../Store';

interface BackgroundMusicProps {
  played?: boolean;
  src: string;
}

const BackgroundMusic = ({ played, src }: BackgroundMusicProps) => {
  const ref: any = useRef(null);
  const { playing } = useSelector((state: RootState) => state.Global);

  useEffect(() => {
    const audio = ref.current as HTMLAudioElement;
    if (played) {
      audio.muted = false;
      audio.play();
    } else {
      audio.muted = true;
      audio.play();
    }
  }, [playing]);

  return (
    <audio className="fixed top-0 left-0 -z-10 opacity-0" ref={ref} muted playsInline loop>
      <source src={src} />
    </audio>
  );
};

export default BackgroundMusic;
