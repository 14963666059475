import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum PageKey {
  HOME = 'HOME',
  MUSIC = 'MUSIC',
  VIDEOS = 'VIDEOS',
}

const initialState = {
  isBurgerActive: false,
  key: PageKey.HOME,
  playing: false,
  fallbackRes: false,
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setIsBurgerActive: (state, { payload }: PayloadAction<boolean>) => {
      state.isBurgerActive = payload;
    },
    setKey: (state, { payload }: PayloadAction<PageKey>) => {
      state.key = payload;
    },
    setPlaying: (state, { payload }: PayloadAction<boolean>) => {
      state.playing = payload;
    },
    setFallbackRes: (state, { payload }) => {
      state.fallbackRes = payload;
    },
  },
});

export const { setIsBurgerActive, setKey, setPlaying, setFallbackRes } = globalSlice.actions;
