import IMG1 from '../../assets/images/OW1.jpeg';

export interface MusicDataTypes {
  img: string;
  title: string;
  link: string;
}

export const musicData: MusicDataTypes[] = [
  {
    img: 'https://res.cloudinary.com/feature-fm/image/fetch/s--syVYmOwL--/f_auto/https%3A%2F%2Fimagestore.ffm.to%2Flink%2F0d47c44ef6c5dd4b9276bae2ec0ba321.jpeg',
    title: 'NGeeYL - Glock 19',
    link: 'https://ngeeyl.ffm.to/glock19',
  },
  {
    img: 'https://res.cloudinary.com/feature-fm/image/fetch/s--IgG-prOV--/f_auto/https%3A%2F%2Fimagestore.ffm.to%2Flink%2F75efc77f6224c9e8a862839df55bd603.jpeg',
    title: 'NGeeYL - Out Da Roof',
    link: 'https://ngeeyl.ffm.to/outdaroof',
  },
  {
    img: IMG1,
    title: 'NGeeYL feat. Lil Uzi Vert - Off-White',
    link: 'https://ngeeyl.ffm.to/offwhite',
  },
  {
    img: 'https://res.cloudinary.com/feature-fm/image/fetch/s--XQ57zJII--/f_auto/https%3A%2F%2Fd9nqml74o2sdi.cloudfront.net%2F4132aa6e-befb-4842-8858-3da29fe2c7c8.c9faae25-7d4a-4399-baf3-3abc7cb8057f',
    title: 'NGeeYL - Live Ammo',
    link: 'https://ngeeyl.ffm.to/liveammo',
  },
  {
    img: 'https://res.cloudinary.com/feature-fm/image/fetch/s--jxvN6LxC--/f_auto/https%3A%2F%2Fi.scdn.co%2Fimage%2F0ba57685b2c8256c2c31bd1f106df8ff64950555',
    title: 'NGeeYL - Hiatus',
    link: 'https://ngeeyl.ffm.to/hiatus',
  },
];

export const musicData2: MusicDataTypes[] = [
  {
    img: 'https://linkstorage.linkfire.com/medialinks/images/a114c3c7-9ee1-41db-91d0-706c764d1e39/artwork-440x440.jpg',
    title: 'NGeeYL - SSetilian',
    link: 'https://ngeeyl.lnk.to/ssetilian',
  },
  {
    img: 'https://linkstorage.linkfire.com/medialinks/images/20a01e29-6d1f-4b60-b4a4-b7261fcd0b50/artwork-440x440.jpg',
    title: 'NGeeYL - Rasputia',
    link: 'https://ngeeyl.lnk.to/rasputia',
  },
  {
    img: 'https://linkstorage.linkfire.com/medialinks/images/36f13f55-96ea-4fec-bc3b-2705f5981ad9/artwork-440x440.jpg',
    title: 'NGeeYL - Fill Up (feat. Baby Drill)',
    link: 'https://ngeeyl.lnk.to/fillup',
  },
  {
    img: 'https://linkstorage.linkfire.com/medialinks/images/ca9d7681-4d76-4d38-8651-0c25eda9310e/artwork-440x440.jpg',
    title: 'NGeeYL - Triple Kross King',
    link: 'https://ngeeyl.lnk.to/triplekrossking',
  },
  {
    img: 'https://cloudinary-cdn.ffm.to/s--I0o5lPGZ--/f_webp/https%3A%2F%2Fimagestore.ffm.to%2Flink%2Fdb649bcacbd1e5a589bfc43071b7c2b9.png',
    title: 'NGeeYL - Showtime',
    link: 'https://ngeeyl.ffm.to/showtime',
  },
  {
    img: 'https://cloudinary-cdn.ffm.to/s--3Q54Ws8V--/f_webp/https%3A%2F%2Fimagestore.ffm.to%2Flink%2F9d05b10f89f324c4f11179e55028e089.png',
    title: 'NGeeYL - Wrong Move (feat. Trippie Redd)',
    link: 'https://ngeeyl.ffm.to/wrongmove',
  },
  {
    img: 'https://res.cloudinary.com/feature-fm/image/fetch/s--eMbsyNaq--/f_auto/https%3A%2F%2Fimagestore.ffm.to%2Flink%2F11d8c1f3bc650d337ef93ed6342e32b9.gif',
    title: 'NGeeYL - Red Light/Escalade',
    link: 'https://ngeeyl.ffm.to/escalade',
  },
  {
    img: 'https://res.cloudinary.com/feature-fm/image/fetch/s--syVYmOwL--/f_auto/https%3A%2F%2Fimagestore.ffm.to%2Flink%2F0d47c44ef6c5dd4b9276bae2ec0ba321.jpeg',
    title: 'NGeeYL - Glock 19',
    link: 'https://ngeeyl.ffm.to/glock19',
  },
  {
    img: 'https://res.cloudinary.com/feature-fm/image/fetch/s--IgG-prOV--/f_auto/https%3A%2F%2Fimagestore.ffm.to%2Flink%2F75efc77f6224c9e8a862839df55bd603.jpeg',
    title: 'NGeeYL - Out Da Roof',
    link: 'https://ngeeyl.ffm.to/outdaroof',
  },
  {
    img: IMG1,
    title: 'NGeeYL feat. Lil Uzi Vert - Off-White',
    link: 'https://ngeeyl.ffm.to/offwhite',
  },
  {
    img: 'https://res.cloudinary.com/feature-fm/image/fetch/s--XQ57zJII--/f_auto/https%3A%2F%2Fd9nqml74o2sdi.cloudfront.net%2F4132aa6e-befb-4842-8858-3da29fe2c7c8.c9faae25-7d4a-4399-baf3-3abc7cb8057f',
    title: 'NGeeYL - Live Ammo',
    link: 'https://ngeeyl.ffm.to/liveammo',
  },
  {
    img: 'https://res.cloudinary.com/feature-fm/image/fetch/s--jxvN6LxC--/f_auto/https%3A%2F%2Fi.scdn.co%2Fimage%2F0ba57685b2c8256c2c31bd1f106df8ff64950555',
    title: 'NGeeYL - Hiatus',
    link: 'https://ngeeyl.ffm.to/hiatus',
  },
];
