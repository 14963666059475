import styled from 'styled-components';

const Iframe = styled.iframe`
  width: 100% !important;
  margin: 0 !important;
  margin-bottom: 1rem !important;
`;

const Container = styled.div`
  max-width: 300px;
  width: 100%;
  height: 240px;

  @media (min-width: 768px) {
    height: 410px;
    max-width: 700px;
  }

  /* @media (min-width: 1368px) {
    height: 
  } */
`;

interface VideoItemProps {
  idx: number;
  videoId: string;
  title: string;
  href?: string;
}

export default function VideoItem({ idx, videoId, title }: VideoItemProps) {
  return (
    <Container key={idx} className="flex justify-center items-center flex-col text-dark">
      <Iframe
        className="optanon-category-C0003 w-full h-full"
        data-src={`https://www.youtube-nocookie.com/embed/${videoId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
      <span className="text-white">
        <h2 className="font-bold font-gotham-black italic text-sm text-shadow md:text-xl lg:text-2xl">
          {title}
        </h2>
        {/* <a
          href={`https://www.youtube-nocookie.com/${videoId}`}
          //   href={item.stream}
          className="uppercase font-calibre-medium underline text-shadow md:text-xl"
        >
          stream / download now
        </a> */}
      </span>
    </Container>
  );
}
