import styled from 'styled-components';
import { ArrowTypes } from './types';

export const Arrow = styled.button<ArrowTypes>`
  position: absolute;
  right: ${(props) => props.right && '47%'};
  left: ${(props) => props.left && '47%'};
  z-index: 2;
  top: 110%;
  width: 60px;
  height: 60px;
  cursor: pointer;
  font-size: 2rem;
  font-weight: 100;
  outline: none;
  color: white;

  :focus {
    outline: none;
  }

  @media (min-width: 1024px) {
    top: 45%;
    right: ${(props) => props.right && '38%'};
    left: ${(props) => props.left && '38%'};
    transform: translateY(-50%);
  }

  @media (min-width: 1280px) {
    right: ${(props) => props.right && props.right};
    left: ${(props) => props.left && props.left};
  }
`;
