export default class VideoFallback {
  private v: HTMLVideoElement;
  private src: string;

  constructor(v: HTMLVideoElement, src: string) {
    this.v = v;
    this.src = src;
  }

  render() {
    let paused = false;

    this.v.play(); // plays the video
    // this.v.pause(); // pauses the video

    if (this.v.paused) {
      const d = document.createElement('img');

      d.src = this.src;
      d.className = 'w-full h-full object-cover block relative z-20';

      this.v.parentNode!.replaceChild(d, this.v);

      paused = true;
    }

    return paused;
  }
}
