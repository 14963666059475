import styled from 'styled-components';
import { useEffect, useRef } from 'react';
import VideoFallback from './VideoFallback';
import useGlobalService from '../../app/Hooks/useGlobalService';
import { useSelector } from 'react-redux';
import { RootState } from '../../Store';

const Container = styled.div`
  position: fixed;
  z-index: -99;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

interface BackgroundVideoProps {
  VIDEOMP4: string;
  VIDEOMP4MOBILE: string;
  VIDEOGIF: string;
  played?: boolean;
}

export default function BackgroundVideo({
  VIDEOGIF,
  VIDEOMP4,
  VIDEOMP4MOBILE,
}: BackgroundVideoProps) {
  const { playing: played } = useSelector((state: RootState) => state.Global);
  const v: any = useRef(null);
  const video = v.current as HTMLVideoElement;

  const { setFallbackRes } = useGlobalService();

  useEffect(() => {
    const videoFallback = new VideoFallback(
      document.getElementById('bg-video') as HTMLVideoElement,
      VIDEOGIF,
    );

    setFallbackRes(videoFallback.render());
  }, []);

  useEffect(() => {
    if (!video) return;

    if (played) {
      video.muted = false;
      video.play();
    } else {
      video.muted = true;
      video.play();
    }
  }, [played, video]);

  return (
    <>
      <Container>
        <video
          ref={v}
          id="bg-video"
          preload="metadata"
          playsInline
          autoPlay
          muted
          loop
          className="w-full h-full object-cover relative z-20"
        >
          {window.screen.width >= 768 ? (
            <source src={VIDEOMP4} type="video/mp4" />
          ) : (
            <source src={VIDEOMP4MOBILE} type="video/mp4" />
          )}
        </video>
      </Container>
      <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 -z-10" />
    </>
  );
}
