import { motion } from 'framer-motion';
import { useState } from 'react';
import { TransitionPowerThree } from '../FramerMotion/Transitions';
import Burger from './Burger';
import { menuItems } from './data';
import { RoutePattern } from '../../routes/RoutePattern';
import styled from 'styled-components';

const Anchor = styled.a``;

const variant = {
  hidden: {
    left: '-100%',
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
  visible: {
    opacity: 1,
    left: '0',
    zIndex: 123123,
    transition: { ...TransitionPowerThree, duration: 1.2 },
  },
};

export default function MobileNavigation() {
  const [isBurgerActive, setIsBurgerActive] = useState(false);

  const renderMenuItems = menuItems.map((item, idx) => (
    <li
      key={idx}
      onClick={() => setIsBurgerActive(false)}
      className={`text-white block text-xl uppercase font-bold cursor-pointer ${
        !item.img && 'py-2'
      }`}
    >
      <Anchor
        href={item.to === RoutePattern.Home ? '/' : '/#' + item.to}
        data-scroll-to={item.to === RoutePattern.Home ? 'home' : '#' + item.to}
        onClick={() => setIsBurgerActive(false)}
      >
        {item.name}
      </Anchor>
    </li>
  ));

  return (
    <div className="flex py-2 px-5 items-center w-full md:w-auto md:hidden z-50">
      <div className="mr-auto" style={{ zIndex: 1231231231 }}>
        <Burger setIsBurgerActive={setIsBurgerActive} isBurgerActive={isBurgerActive} />
      </div>
      <div>
        <Anchor
          href={RoutePattern.ContactUs}
          className="px-5 py-2 text-sm bg-purple-500 text-white"
        >
          Join Us
        </Anchor>
      </div>
      <motion.div
        variants={variant}
        animate={isBurgerActive ? 'visible' : 'hidden'}
        className="flex flex-col justify-center items-center fixed top-0 left-0 text-white bg-black bg-opacity-90 w-full min-h-screen opacity-0"
      >
        <ul className="flex flex-col -mt-12">{renderMenuItems}</ul>
      </motion.div>
    </div>
  );
}
