import styled from 'styled-components';
const Anchor = styled.a`
  cursor: pointer;
`;

const Copyright = () => {
  return (
    <div className="text-white text-xxs lg:text-lg">
      <Anchor
        className="mr-2"
        href="https://privacy.wmg.com/atlantic/privacy-policy"
        target="_blank"
      >
        Privacy Policy
      </Anchor>
      <span className="mr-2">|</span>
      <Anchor
        className="mr-2"
        href="https://www.atlanticrecords.com/terms-of-use"
        target="_blank"
      >
        Terms of Use
      </Anchor>
      <span className="mr-2">|</span>
      <Anchor
        className="mr-2"
        href="https://www.wminewmedia.com/cookies-policy/?ot=7fda66bb-299e-4a2c-8f75-21b6b604dc92&url=bigbaglegend.com"
        target="_blank"
      >
        Cookies Policy
      </Anchor>
      <span className="mr-2">|</span>
      <Anchor className="ot-sdk-show-settings">Do Not Sell My Personal Information</Anchor>
    </div>
  );
};

export default Copyright;
