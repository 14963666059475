import { motion } from 'framer-motion';
import styled from 'styled-components';
import { YoutubeVideoSlider } from '../../components';
import { SlideVariant } from '../../components/FramerMotion/Slide';
import { PageKey } from '../../features/Global/Global.slice';
import useGlobalService from '../Hooks/useGlobalService';
import { VideoData } from './VideoData';

const Container = styled(motion.div)`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
`;

interface MusicProps {
  show?: boolean;
}

const Videos = ({ show }: MusicProps) => {
  const { setKey } = useGlobalService();

  return (
    <Container initial="hidden" animate={show ? 'visible' : 'hidden'} variants={SlideVariant}>
      <div className="relative">
        <button
          className="flex w-10 h-10 m-0 text-xl leading-0 text-white absolute top-5 right-5 hover:text-primary"
          onClick={() => setKey(PageKey.HOME)}
        >
          <span className="m-auto">x</span>
        </button>
      </div>
      <div className="flex justify-center items-center h-full">
        <div className="m-auto">
          <YoutubeVideoSlider playlistId="PLhFEVXB_4yfSmDbt2aikY-NGX4b-IhY7O" data={VideoData} />
        </div>
      </div>
    </Container>
  );
};

export default Videos;
